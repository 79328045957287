<template>
  <b-container fluid>
    <bo-page-title>
      <template #additionalCta>
        <b-button
          variant="success"
          class="m-l-15 btn-rounded"
          @click="openModalAdd"
        >
          <i class="fa fa-plus-circle mr-2" />
          Add {{ objName }}
        </b-button>
      </template>
    </bo-page-title>

    <b-card no-body>
      <b-card-header>
        <b-row>
          <b-col lg="7">
            <h5 class="card-title">{{ pageTitle }} List </h5>
          </b-col>
          <b-col lg="5">
            <SearchInput :value.sync="filter.search" @search="doFill" />
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body class="p-0">
        <b-table
          :fields="fields"
          :items="dataList||[]"
          :per-page="perPage"
          :primary-key="idKey"
          :busy="!dataList"
          responsive
          show-empty
          striped
        >
          <template #empty>
            <div class="text-center">
              <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
              <h4 align="center"><span v-if="Object.keys(filter).length">No search results found</span><span v-else>No {{ pageTitle }} listed yet</span></h4>
            </div>
          </template>
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(number)="v">
            {{(data.per_page*(data.current_page-1))+v.index+1}}
          </template>
          <template #cell(name)="data">
            {{data.item.mi_name_id}} / {{data.item.mi_name_en}}
          </template>
          <template #cell(icon)="data">
            <i :class="data.item.mi_icon"></i> {{data.item.mi_icon}}
          </template>
          <template
            #cell(mi_is_active) = "data"
          >
            <b-badge
              v-if="data.value=='Y'"
              variant="success"
            >Active</b-badge>
            <b-badge
              v-else
              variant="danger"
            >Inactive</b-badge>
          </template>
          <template
            #cell(action)="data"
          >
            <b-button
              v-if="moduleRole('edit')"

              v-b-tooltip.hover.noninteractive
              title="Edit"
              class="icon_action"
              variant="outline-primary"
              size="sm"
              @click="openModalEdit(data.item)"
            >
              <i class="ti-marker-alt"></i>
            </b-button>
            <b-button
              v-if="moduleRole('edit')"

              v-b-tooltip.hover.noninteractive
              title="Change Status"
              class="icon_action"
              variant="outline-info"
              size="sm"
              @click="doChangeStatus(data.index, data.item)"
            >
              <i class="ti-settings"></i>
            </b-button>
            <b-button
              v-if="moduleRole('delete')"

              v-b-tooltip.hover.noninteractive
              title="Delete"
              class="icon_action"
              variant="outline-danger"
              size="sm"
              @click="doDelete(data.index, data.item)"
            >
              <i class="ti-trash"></i>
            </b-button>

            <span v-if="!(moduleRole('delete') || moduleRole('changeStatus') || moduleRole('edit'))">-</span>
          </template>
        </b-table>
      </b-card-body>
      <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>10">        
        <b-pagination
        class="mb-0"
        v-model="pageNo"
        :per-page="data.per_page"
        :total-rows="data.total"
        />
      </b-card-footer>
    </b-card>

    <b-modal id="modal_form" :title="(dataModal.mc_id ? 'Update' : 'Add') + ' Industry'" no-close-on-backdrop>
      <validation-observer ref="VForm" v-slot="{handleSubmit}">
        <b-form @submit.prevent="handleSubmit(submit)">
          <b-form-group>
            <template #label>Name (ID) <span class="text-danger">*</span></template>
            <b-form-input v-model="dataModal.mi_name_id" placeholder="e.g. Edutech"></b-form-input>
            <VValidate name="Name (ID)" v-model="dataModal.mi_name_id" :rules="mrValidation.mi_name_id" />
          </b-form-group>
          <b-form-group>
            <template #label>Name (EN) <span class="text-danger">*</span></template>
            <b-form-input v-model="dataModal.mi_name_en" placeholder="e.g. Edutech"></b-form-input>
            <VValidate name="Name (EN)" v-model="dataModal.mi_name_en" :rules="mrValidation.mi_name_en" />
          </b-form-group>
          <b-form-group>
            <template #label>Icon <span class="text-danger">*</span></template>
            <b-input-group>
                <template #append>
                    <b-input-group-text><i :class="dataModal.mi_icon"></i></b-input-group-text>
                </template>
                <b-form-input v-model="dataModal.mi_icon"></b-form-input>
            </b-input-group>
            <VValidate name="Icon" v-model="dataModal.mi_icon" :rules="mrValidation.mi_icon" />
          </b-form-group>
          <b-form-group>
            <label>Status<span class="text-danger mr5">*</span></label>
            <b-form-radio-group
              :options="Config.mr.StatusOptions"
              v-model="dataModal.mi_is_active"
            />
            <VValidate 
              name="Status" 
              v-model="dataModal.mi_is_active" 
              :rules="mrValidation.mi_is_active" 
            />
          </b-form-group>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <b-button type="button" variant="secondary" @click="closeModal">Close</b-button>
        <b-button type="button" variant="primary" @click="submit">Submit</b-button>
      </template>
    </b-modal>

  </b-container>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
const _ = global._

export default {
  extends: GlobalVue,
  data() {
    return {
      idKey:'mi_id',
      statusKey:'mi_is_active',
      fields: [
        {
          key: 'number',
          label: '#',
        },
        {
          key: 'name',
          label: 'Name',
        },
        
        {
          key: 'icon',
          label: 'Icon',
        },
        {
          key: 'mi_is_active',
          label: 'Status',
        },
        {
          key: 'action',
        },
      ],
      dataModal: {}
    }
  },
  mounted(){
    this.apiGet()
  },
  methods: {
    doFill(){
      this.doFilter()
    },
    openModalEdit(value){
      this.$set(this, 'dataModal', _.clone(value))
      this.$bvModal.show('modal_form')
    },
    openModalAdd(){
      this.$set(this, 'dataModal', {
        mi_is_active: 'Y'
      })
      this.$bvModal.show('modal_form')
    },
    closeModal(){
      this.$set(this, 'dataModal', {})
      this.$bvModal.hide('modal_form')
    },
    submit(){
      this.$refs.VForm.validate().then(success => {
        if(!success) return

        this.swalLoading()

        Gen.apiRest("/do/" + this.modulePage, {
          data: {
            type: this.dataModal.mi_id ? 'update' : 'add',
            ...this.dataModal
          }
        }).then(res => {
          this.$swal({
            icon: 'success',
            text: res.data.message
          }).then(() => {
            this.$bvModal.hide('modal_form')
            this.apiGet()
          })
        }).catch(err => {
          this.$swal({
            icon: 'error',
            text: err.response.data.message || 'Terjadi Suatu Kesalahan!'
          })
        })
      })
    },
  },
  watch:{
    $route(){
      this.apiGet()
    },
    '$route.params.pageSlug':function(){
      this.$set(this,'filter',{})
    }
  }
}
</script>